import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/resume.png';
import resume from '../assets/images/KarinaResume.pdf';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Resume</h2>
        {/* <p>Super snazzy hook to get them to download the resume</p> */}

        <a
          href={resume}
          download="KarinaGutierrezResume"
          className="button fit primary"
        >
          Download Resume
        </a>
      </header>
      <section className="wrapper style5">
        <div className="resume__box">
          <img src={pic1} alt="resume" className="resume" />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
